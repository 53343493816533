import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/netstorm-json-1/wallet";

class Collection extends Component {
    state = {
        data: {},
        walletData: []
    }
    componentDidMount() {
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    walletData: res.data.walletData
                })
                // console.log(this.state.data)
            })
            .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="wallet-connect-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                                <span>MULTI-CHAIN META WAIFUS</span>
                                <h3 className="mt-3 mb-0">FAIR DISTRIBUTION</h3>
                                <p>10,000 unique WAIFUs will be distributed across multiple blockchains.</p>
                                <p>The objective of the distribution is to make it fair and available to as many people as possible across multiple blockchains. For this reason, distribution on each blockchain will be different and could include mechanics such as paint-to-earn, farming and public sales.</p>
                                <p>WAIFUS will be released in batches as they become available.</p>

                            </div>
                            <h2 className="text-center mt-5 mb-4">Methods</h2>
                            <div className="intro text-center">
                                <h3 className="mt-3 mb-0">1) PAINT-TO-EARN</h3>
                                <p>Paint to earn is a new and unique mechanic introduced by MetaWaifus that enables users to paint pixels on a decentralized canvas stored on the blockchain. Anyone can paint on this canvas, and everything is stored on smart contracts. Users earn WAIFU (ERC-20) tokens for each pixel they paint, and these tokens can later be redeemed for WAIFU NFTS.</p>
                                <p>Pixel fees and redemption rates will be announced on each blockchain before distribution begins.</p>

                                <h3 className="mt-3 mb-0">2) SALES</h3>
                                <p>WAIFUS will be sold on Ethereum, and other blockchains, at single price, without price tiers or bonding curves.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src="/img/waifu/eva3-2.png" />
                    </div>
                </div>
            </section>
        );
    }
}

export default Collection;