import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/netstorm-json-1/wallet";

class PornFi extends Component {
    state = {
        data: {},
        walletData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    walletData: res.data.walletData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="wallet-connect-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                                <span>The begining</span>
                                <h3 className="mt-3 mb-0">Be part of the PornFi Revolution</h3>
                                <p>Welcome to the future of gaming, finance and porn! MetaWaifus is the genesis of a new type of ecosystem that combines GameFi and play to earn mechanics with adult content and porn to create a revolutionary new type of experience.</p>
                                <p>All characters (WAIFUS), weapons, cosmetic items and unlockable content are represented by non-fungible tokens (NFTS). This means players have true ownership of their assets and content, and will be able to interoperate with the metaverse through the power of blockchain.</p>
                                <p>All WAIFU characters include unlockable adult content like custom porn scenes, skins, poses, AR dioramas, VR experiences and much more!</p>
                                <p>Earn tokens by collecting WAIFUs and battling with other player with play to earn mechanics.</p>
                            </div>
                        </div>
                    </div>
                   <div>
                        <img src="/img/waifu/waifu_lab2.png" />
                    </div>
                </div>
            </section>
        );
    }
}

export default PornFi;