import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/netstorm-json/work";

class Collection extends Component {
    state = {
        data: {},
        workData: []
    }
    componentDidMount() {
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    workData: res.data.workData
                })
                // console.log(this.state.data)
            })
            .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="work-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro mb-4">
                                <div className="intro-content">
                                    <span>RARE WAIFUS</span>
                                    <h3 className="mt-3 mb-0">LIMITED NFT COLLECTION</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row items">

                        <div className="col-12 col-sm-6 col-lg-3 item">
                            {/* Single Work */}
                            <div className="single-work">
                                <i className="icons icon-grid text-effect" />
                                <h4>Premium NFT Collection</h4>
                                <p>MetaWaifus is a multi-chain collection of 10,000 NFTs. Waifus are interoperable characters across the DeFi Mafia metaverse and its own PornFi universe.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 item">
                            {/* Single Work */}
                            <div className="single-work">
                                <i className="icons icon-wallet text-effect" />
                                <h4>Play 2 Earn</h4>
                                <p>Earn MOOLAH token by playing on DeFi Mafia or MetaWaifus (the first PornFi play-to-earn universe).</p>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3 item">
                            {/* Single Work */}
                            <div className="single-work">
                                <i className="icons icon-target text-effect" />
                                <h4>GameFi For Adults</h4>
                                <p>Welcome to GameFi for mature audiences and the future of porn.</p>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3 item">
                            {/* Single Work */}
                            <div className="single-work">
                                <i className="icons icon-drawer text-effect" />
                                <h4>Exclusive Content</h4>
                                <p>Unlock exclusive content for your WAIFU: poses, skins, porn scenes, AR dioramas {'&'} VR experiences.</p>
                            </div>
                        </div>

                        {/* <div className="col-12 col-sm-6 col-lg-3 item">
                            <div className="single-work">
                                <i className="icons icon-drawer text-effect" />
                                <h4>DeFi Mafia Metaverse</h4>
                                <p>One colleciton, multiple universes. The first generation of characters interoperable with the DeFi Mafia metaverse.</p>
                            </div>
                        </div> */}
                    </div>



                    

                </div>
            </section>
        );
    }
}

export default Collection;