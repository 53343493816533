import React, { Component, Fragment } from 'react';


class WaifuSection4 extends Component {
    state = {
        data: {},
        workData: []
    }
    componentDidMount() {

    }
    render() {
        return (

            <Fragment>
                <div className="row waifu-section-4">
                    <div className="col-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 offset-md-6">
                                    <div className="waifu-section-text-container2">
                                        <div className="intro mb-4" >
                                            <div className="intro-content text-right">
                                                {/* <h3 className="mt-3 mb-0">JOIN THE DEFI MAFIA MULTIVERSE</h3> */}
                                                <h3 className="mt-3 mb-0">LIMITED NFT COLLECTION</h3>
                                                <div className="waifu-section-title-separator"></div>
                                                <div className="waifu-section-text">
                                                    Get one of the limited 10,000 WAIFU characters to enter the PornFi ecosystem. Unlock unique NSFW skins, poses, porn scenes and VR/AR content for your WAIFU. 
                                                    {/* META WAIFUs are the first limited edition characters interoperable with the DeFi Mafia multiverse, a revolutionary play-to-earn GameFi metaverse. */}
                                                </div>
                                                <div className="button-group">
                                                    <a className="btn btn-bordered-white" href="/explore-1"><i className="icon-rocket mr-2"></i>Get my WAIFU</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}

export default WaifuSection4;