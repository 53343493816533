import React, { Component } from 'react';
import Countdown from 'react-countdown';

const initData = {
    pre_heading: "3D Characters for the Metaverse Revolution",
    // heading: "DEFI MAFIA",
    // content: "A revolutionary GameFi metaverse",
    heading: "META WAIFUS",
    content: "MW is a 3D collection of 5600 NFTs living in the Solana blockchain. Each MetaWaifu NFT represents a 3D character that can be used in a unique gaming metaverse.",
    btn_1: "Explore",
    btn_2: "Create"
}

class Hero extends Component {
    state = {
        data: {}
    }
    componentDidMount() {
        this.setState({
            data: initData
        })
    }

    renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <a style={{ backgroundImage: 'linear-gradient( 150deg, var(--secondary-color) 0%, var(--primary-color) 78%)', borderColor: 'transparent', fontSize: 30 }} className="btn btn-bordered-white" href="https://mint.metawaifus.com"><i className="icon-rocket mr-2" />MINT NOW!</a>
        } else {
            return <div>
                <div style={{ fontWeight: 'bold', fontSize: 28, color: 'white' }}>Public Mint II</div>
                <div style={{fontSize: 40, color: 'white', fontWeight:'bold'}}>{days}:{hours}:{minutes}:{seconds}</div>
            </div>
        }
    }

    render() {
        return (
            <section className="hero-section">

                <div className="container" >
                    <div className="row" style={{ marginTop: '25%' }}>
                        <div className="col-12 col-md-6 col-lg-7">
                            <span >{this.state.data.pre_heading}</span>
                            <h1 className="mt-4" >{this.state.data.heading}</h1>
                            <p >{this.state.data.content}</p>
                            {/* Buttons */}
                            <div className="button-group">
                                {/* <Countdown date={1642194660000} renderer={this.renderer} /> */}
                                {/* <a className="btn btn-bordered-white" target="_blank" href="https://discord.com/invite/uxG9y6Nz4k"><i className="icon-rocket mr-2" />Join our Discord</a> */}

                            </div>
                        </div>
                    </div>
                </div>
                <div id="video-container">
                    <div class="overlay"></div>
                    <video id="myvideo" autoPlay loop muted="muted" >
                        <source id="mp4" src="https://metawaifus.com/img/waifu/waifus_gallery_hd.mp4" type="video/mp4" />
                        {/* <source id="webm" src="https://metawaifus.com/img/waifu/waifus_gallery_hd.webm" type="video/webm" /> */}
                        {/* <source id="ogv" src="http://media.w3.org/2010/05/sintel/trailer.ogv" type="video/ogg" /> */}
                        Your browser does not support the video tag. I suggest you upgrade your browser.
                    </video>
                </div>
            </section>
        );
    }
}

export default Hero;