import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/netstorm-json-2/footer";

class Footer extends Component {
    state = {
        data: {},
        socialData: [],
        widgetData_1: [],
        widgetData_2: []
    }
    componentDidMount() {
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    socialData: res.data.socialData,
                    widgetData_1: res.data.widgetData_1,
                    widgetData_2: res.data.widgetData_2
                })
                // console.log(this.state.data)
            })
            .catch(err => console.log(err))
    }
    render() {
        return (
            <footer className="footer-area">
                {/* Footer Top */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Logo */}
                                    <a className="navbar-brand" href="/">
                                        {/* <img src="img/waifu/logo.png" alt="" style={{ height: 64 }} /> */}
                                        <h1 className="" style={{ color: 'white', fontSize: 24, marginBottom: 0 }}>MetaWaifus™</h1>
                                    </a>
                                    <p>A Premium NFT Collection on Solana built for the Metaverse.</p>
                                    {/* Social Icons */}
                                    <div className="social-icons d-flex">
                                        <a className="twitter" href="https://twitter.com/MetaWaifus">
                                            <i className="fab fa-twitter" />
                                            <i className="fab fa-twitter" />
                                        </a>
                                        {/* <a className="telegram" href="#">
                                            <i className="fab fa-telegram" />
                                            <i className="fab fa-telegram" />
                                        </a> */}
                                        <a target="_blank" className="discord" href="https://discord.com/invite/uxG9y6Nz4k">
                                            <i className="fab fa-discord" />
                                            <i className="fab fa-discord" />
                                        </a>
                                        <a target="_blank" className="reddit" href="https://reddit.com/r/MetaWaifus">
                                            <i className="fab fa-reddit" />
                                            <i className="fab fa-reddit" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    {/* <h4 className="footer-title">Useful Links</h4>
                                    <ul>
                                        <li><a target="_blank" href="">MetaWaifus</a></li>
                                        <li><a target="_blank" href="">Medium</a></li>
                                        <li><a target="_blank" href="#">Docs (Coming soon)</a></li>
                                    </ul> */}
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h4 className="footer-title">Community</h4>
                                    <ul>
                                        <li><a target="_blank" href="https://twitter.com/MetaWaifus">Twitter</a></li>
                                        <li><a target="_blank" href="https://t.co/XAazaO0d0M?amp=1">Discord</a></li>
                                        <li><a target="_blank" href="https://reddit.com/r/MetaWaifus">Reddit</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="footer-items">
                                    <h4 className="footer-title">Join the community</h4>
                                    <a target="_blank" href="https://discord.com/invite/uxG9y6Nz4k" className="btn">Join our Discord</a>
                                    {/* <h4 className="footer-title">{this.state.data.widget_3}</h4>
                                    <div className="subscribe-form d-flex align-items-center">
                                        <input type="email" className="form-control" placeholder="info@yourmail.com" />
                                        <button type="submit" className="btn"><i className="icon-paper-plane" /></button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                    {/* Copyright Left */}
                                    <div className="copyright-left">©2021 MetaWaifus, All Rights Reserved.</div>
                                    {/* Copyright Right */}
                                    <div className="copyright-right">Made with <i className="fas fa-heart" /> By <a href="#">MetaWaifus</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;