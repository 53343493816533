import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Auctions from '../components/Auctions/AuctionsOne';
import TopSeller from '../components/TopSeller/TopSellerOne';
import Collections from '../components/Collections/Collections';
import Explore from '../components/Explore/ExploreOne';
import Work from '../components/Work/Work';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import WaifuSection1 from '../components/WaifuSections/WaifuSection1';
import WaifuSection2 from '../components/WaifuSections/WaifuSection2';
import WaifuSection3 from '../components/WaifuSections/WaifuSection3';
import WaifuSection4 from '../components/WaifuSections/WaifuSection4';
import Collection from '../components/Work/Collection';
import Faq from '../components/Faq/Faq';

class ThemeOne extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <Hero />
                {/* <Auctions />
                <TopSeller />
                <Collections />
                <Explore /> */}
                <Work />
                <WaifuSection1/>
                {/* <Collection/> */}
                
                <Faq/>
                <WaifuSection2/>
                {/* <WaifuSection3/> */}

                {/* <WaifuSection4/> */}
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default ThemeOne;