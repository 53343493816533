import React, { Component } from 'react';
import axios from 'axios';
import * as THREE from "three";

const BASE_URL = "https://my-json-server.typicode.com/themeland/netstorm-json/work";

class Work extends Component {
    state = {
        data: {},
        workData: []
    }
    componentDidMount() {
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    workData: res.data.workData
                })
                // console.log(this.state.data)
            })
            .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="work-area" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro mb-4">
                                <div className="intro-content">
                                    <span>Features</span>
                                    <h3 className="mt-3 mb-0">What is MetaWaifus?</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row items">

                        <div className="col-12 col-sm-6 col-lg-3 item">
                            {/* Single Work */}
                            <div className="single-work">
                                <i className="icons icon-grid text-effect" />
                                <h4>Premium 3D NFTs</h4>
                                <p>MW is a collection of 5600 NFTs living in the Solana blockchain. Each NFT represents a 3D character usable in MetaWaifus' metaverse.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 item">
                            {/* Single Work */}
                            <div className="single-work">
                                <i className="icons icon-wallet text-effect" />
                                <h4>The key to the Metaverse</h4>
                                <p>Your Meta Waifu NFT represents your membership and access to exclusive content in an ecosystem of games and multimedia content.</p>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3 item">
                            {/* Single Work */}
                            <div className="single-work">
                                <i className="icons icon-target text-effect" />
                                <h4>In-game characters</h4>
                                <p>Each NFT was rendered from 3D models that can be used as in-game characters in MetaWaifus' metaverse.</p>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3 item">
                            {/* Single Work */}
                            <div className="single-work">
                                <i className="icons icon-drawer text-effect" />
                                <h4>Exclusive Ecosystem</h4>
                                <p>By owning a MW NFT you gain exclusive access to a multimedia ecosystem built for the metaverse age.</p>
                            </div>
                        </div>
                    </div>



                    

                </div>
            </section>
        );
    }
}

export default Work;