import React, { Component } from 'react';

const initData = {
    pre_heading: "FAQ",
    heading: "Frequently Asked Questions",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit."
}

const data = [
    {
        id: "1",
        btnClass: "btn d-block text-left w-100 py-4",
        target: "#collapseOne",
        quote: "What are MetaWaifus?",
        contentId: "collapseOne",
        contentClass: "collapse show",
        content: "MetaWaifus are a collection of 5600 unique 3D characters rendered as NFTs on the Solana blockchain. MetaWaifus can be used as in-game characters in CW' metaverse, and can also be used to generate other types of multimedia content like 3D animations, Visual Novels, and among other possibilities."
    },
    {
        id: "2",
        btnClass: "btn d-block text-left w-100 collapsed py-4",
        target: "#collapseTwo",
        quote: "How can I mint a Meta Waifu?",
        contentId: "collapseTwo",
        contentClass: "collapse",
        content: "When the mint starts head over to the mint page. You will be prompted to connect your Phantom or Sollet wallet. Once your wallet is connected you will be taken to another screen where you will be able to get a Meta Waifu by clicking on the 'MINT' button, and then confirming the transaction on your wallet."
    },
    {
        id: "3",
        btnClass: "btn d-block text-left w-100 collapsed py-4",
        target: "#collapseThree",
        quote: "When is the drop? How much?",
        contentId: "collapseThree",
        contentClass: "collapse",
        content: "The drop date has not yet been confirmed, but we are aiming to release the collection towards the middle/end of November. The price will between 1-2 SOL range, but the exact price will be announced near the release date."
    },
    {
        id: "4",
        btnClass: "btn d-block text-left w-100 collapsed py-4",
        target: "#collapseFour",
        quote: "What's the utility of MetaWaifus' NFTs",
        contentId: "collapseFour",
        contentClass: "collapse",
        content: "MetaWaifus are 3D NFTs that can be used as in-game characters in the MW metaverse game and other multimedia content that we'll start developing once a certain percentage of the NFT collection is sold. Holders will also be able to visualize their NFT in AR and have the opportunity to have their character featured in an Interactive Visual Novel Game, as well as in 3D animated episodes where the community have decision over story."
    },
    {
        id: "5",
        btnClass: "btn d-block text-left w-100 collapsed py-4",
        target: "#collapseFive",
        quote: "Who are the team?",
        contentId: "collapseFive",
        contentClass: "collapse",
        content: "We are a multidisciplinary team of 3D artists, blockchain & game developers, and animators working hard to bring MetaWaifus' metaverse to life. We are hugely passionate about the future of games and multimedia content combined with blockchain tech."
    },
    {
        id: "6",
        btnClass: "btn d-block text-left w-100 collapsed py-4",
        target: "#collapseFive",
        quote: "How to contact you?",
        contentId: "collapseFive",
        contentClass: "collapse",
        content: "Twitter: @MetaWaifus"
    }
]

class Faq extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="faq" className="faq-area ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                                <span>{this.state.initData.pre_heading}</span>
                                <h3 className="mt-3 mb-0">{this.state.initData.heading}</h3>
                                {/* <p>{this.state.initData.content}</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            {/* FAQ Content */}
                            <div className="faq-content">
                                {/* Netstorm Accordion */}
                                <div className="accordion" id="netstorm-accordion">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-10">
                                            {/* Single Accordion Item */}
                                            {this.state.data.map((item, idx) => {
                                                return (
                                                    <div key={`fd_${idx}`} className="single-accordion-item p-3">
                                                        {/* Card Header */}
                                                        <div className="card-header bg-inherit border-0 p-0">
                                                            <h2 className="m-0">
                                                                <button className={item.btnClass} type="button" data-toggle="collapse" data-target={item.target}>
                                                                    {item.quote}
                                                                </button>
                                                            </h2>
                                                        </div>
                                                        <div id={item.contentId} className={item.contentClass} data-parent="#netstorm-accordion">
                                                            {/* Card Body */}
                                                            <div className="card-body py-3">
                                                                {item.content}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Faq;