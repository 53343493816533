import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/netstorm-json-1/wallet";

class Collection extends Component {
    state = {
        data: {},
        walletData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    walletData: res.data.walletData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="wallet-connect-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                                <span>META WAIFUS</span>
                                <h3 className="mt-3 mb-0">LIMITED NFT COLLECTION</h3>
                                <p>Each WAIFU is unique and programmatically generated from a pool of possible traits, expressions, clothes, colors and more. Some WAIFUs are rarer than others and contain exclusive and custom unlockable content.</p>
                                <p>WAIFUS are stored as NFTs on solana blockchain. </p>
                                {/* <p>There are only 10,000 unique WAIFUS, distributed on multiple blockchains through different mechanisms.</p> */}
                                <p>WAIFUS enable users to receive additional drops and unlockable content through PornFi mechanics.</p>                                
                            </div>
                        </div>
                    </div>
                   <div>
                        <img src="/img/waifu/waifu_lab3.png" />
                    </div>
                </div>
            </section>
        );
    }
}

export default Collection;