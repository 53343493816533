import React, { Component, Fragment } from 'react';


class Work extends Component {
    state = {
        data: {},
        workData: []
    }
    componentDidMount() {

    }
    render() {
        return (

            <Fragment>
                <div id="roadmap" className="row waifu-section-1">
                    <div className="col-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 ">
                                    <div className="waifu-section-text-container1">
                                        <div className="intro mb-4" >
                                            <div className="intro-content">
                                                <h3 className="mt-3 mb-0">ROADMAP</h3>
                                                <div className="waifu-section-title-separator"></div>
                                                <div className="waifu-section-text">
                                                    We are building a unique universe of utility and content for people who love NFTs. Let's make history together!
                                                </div>

                                                <div className="row items">

                                                    <div className="col-12 col-sm-6 col-lg-3 item">
                                                        {/* Single Work */}
                                                        <div className="single-work">
                                                            
                                                            <h4>25%</h4>
                                                            <p className="roadmap-text">&#8226; List MetaWaifus in the biggest NFT marketplaces in the Solana ecosystem.</p>
                                                            <p className="roadmap-text">&#8226; <b>MetaWaifus Master App</b> to allow the visualization of the MW NFT collection with AR capabilities, and to be used a the access point to the MW metaverse.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-3 item">
                                                        {/* Single Work */}
                                                        <div className="single-work">
                                                            
                                                            <h4>50%</h4>
                                                            <p className="roadmap-text">&#8226; We'll start working on an <b>Interactive Visual Novel game</b> focused on developing MetaWaifus' origin story and universe.</p>
                                                            <p className="roadmap-text">&#8226; <b>Premium Merchandise</b> with free drops and discount for members to bring MW content to the real world.</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-3 item">
                                                        {/* Single Work */}
                                                        <div className="single-work">                                                            
                                                            <h4>75%</h4>
                                                            <p className="roadmap-text">&#8226; Start producing <b>3D animated episodes</b> exploring Meta Waifu' universe. Owners will have a chance at getting their characters included in the animated series, and the story will be decided by holders.</p>
                                                            <p className="roadmap-text">&#8226; <b>MW Lottery</b> with up to $25k for grabs where NFT owners are entered in a draft to earn different prize tiers.</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-3 item">
                                                        {/* Single Work */}
                                                        <div className="single-work">
                                                            
                                                            <h4>100%</h4>
                                                            <p className="roadmap-text">&#8226; <b>Play-to-earn Game</b>: once all MW NFTs are sold, we'll expand the team to start working on a game where players use their NFTs as in-game characters and earn tokens by playing.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="button-group">
                                                    <a className="btn btn-bordered-white" href="/pornfi"><i class="icon-rocket mr-2"></i>Join our Discord community</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </Fragment>

        );
    }
}

export default Work;