import React, { Component, Fragment } from 'react';


class WaifuSection3 extends Component {
    state = {
        data: {},
        workData: []
    }
    componentDidMount() {

    }
    render() {
        return (

            <Fragment>
                <div id="distribution" className="row waifu-section-3">
                    <div className="col-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="waifu-section-text-container1">
                                        <div className="intro mb-4" >
                                            <div className="intro-content">
                                                <h3 className="mt-3 mb-0">MINTING ON SOLANA</h3>
                                                <div className="waifu-section-title-separator"></div>
                                                <div className="waifu-section-text">
                                                    10,000 MetaWaifus will be available for minting through our website on Solana blockchain. Pre-sale and public sale will be announced soon!
                                                </div>
                                                {/* <div className="button-group">
                                                    <a className="btn btn-bordered-white" href="/distribution"><i class="icon-rocket mr-2"></i>Read more about distribution</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </Fragment>

        );
    }
}

export default WaifuSection3;